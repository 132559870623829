import React from "react";
import "./underconstruction.css";       // For Styling

const underconstruction = () => {
    return (
        <div className="under-construction">
            <header>
                <img src="/Logo - Banner.png" alt="Bang Giri" className="logo" />
            </header>
            <main>
                <h1>Website ini masih dalam pengembangan</h1>
                <p>Kami sangat tidak sabar untuk bisa segera melayani Anda!</p>
            </main>
            <footer>
                <p>&copy; 2024 SatuMekanik. All rights reserved.</p>
                <nav>
                    <a href="#">Kebijakan Privasi</a> | <a href="#">Syarat dan Ketentuan</a> | <a href="#">Tentang Kami</a>
                </nav>
                <div className="social-icons">
                    <a
                        href="https://www.instagram.com/satumekanik/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src="/static/media/IG Logo - White.png"
                            alt="Instagram"
                            className="social-icon"
                        />
                    </a>
                    <a
                        href="https://x.com/SatuMekanik"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src="/static/media/X Logo - White.png"
                            alt="X"
                            className="social-icon"
                        />
                    </a>
                </div>
            </footer>
        </div>
    );
};

export default underconstruction;